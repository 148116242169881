<template>
  <div class="percent">
    <iconic class="percent-bg" name="certificate" />
    <div class="percent-discount">
      <span class="percent-number">{{ getDiscountPercent(num, total) }}</span>
      <span class="percent-symbol">%</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["num", "total"],
};
</script>

<style lang="scss" scoped>
.percent {
  font-size: 80%;
  position: relative;
  width: 23px;
  height: 30px;
  display: flex;
  color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  &-bg {
    color: $primary_color;
    position: absolute;
    top: -2px;
    left: -2px;
    font-size: 210%;
    z-index: 0;
  }
  &-number,
  &-symbol {
    position: relative;
    z-index: 1;
    font-size: 80%;
    font-weight: normal;
  }
  &-discount {
    position: relative;
    z-index: 1;
  }
}
</style>
